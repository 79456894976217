import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import styles from "../assets/css/help.module.scss";
import { Link } from "gatsby-plugin-intl";
import "../assets/css/helpAccordion.scss";

const AvisoPage = () => (
	<Layout>
		<Seo title="Aviso Legal" />
		<section className={styles.help}>
			<article className={styles.bgHelp}>
				<div className={styles.helpWrapper}>
					<Link to="/" className="lineLink">
						<u>Volver a la home</u>
					</Link>
				</div>
				<div className={styles.titleWrapper}>
					<h2>Aviso Legal</h2>
				</div>
			</article>

			<article className={styles.bgText}>
				<div className={styles.text}>
					<h3>OBJETO</h3>
					<p>
						El presente aviso legal regula el uso y utilización del sitio
						web{" "}
						<Link to="/" className="lineLink white">
							<u>www.freshsnow.pro</u>
						</Link>{" "}
						del que es titular <b>BANSAH & HOHOE S.L.</b> (EL PROPIETARIO
						DE LA WEB)
						<br />
						<br />
						La navegación por el sitio web de{" "}
						<b>EL PROPIETARIO DE LA WEB</b>, le atribuye la condición de
						USUARIO del mismo y conlleva su aceptación plena y sin
						reservas de todas y cada una de las condiciones publicadas en
						este aviso legal, advirtiendo de que dichas condiciones podrán
						ser modificadas sin notificación previa por parte de{" "}
						<b>EL PROPIETARIO DE LA WEB</b> en cuyo caso se procederá a su
						publicación y aviso con la máxima antelación posible.
						<br />
						<br />
						Por ello es recomendable leer atentamente su contenido en caso
						de desear acceder y hacer uso de la información y de los
						servicios ofrecidos desde este sitio web.
						<br />
						<br />
						El usuario además, se obliga a hacer un uso correcto del sitio
						web de conformidad con las leyes, la buena fe, el orden
						público, los usos del tráfico y el presente Aviso Legal, y
						responderá frente a <b>EL PROPIETARIO DE LA WEB</b> o frente a
						terceros, de cualesquiera daños y perjuicios que pudieran
						causarse como consecuencia del incumplimiento de dicha
						obligación.
						<br />
						<br />
						Cualquier utilización distinta a la autorizada está
						expresamente prohibida, pudiendo{" "}
						<b>EL PROPIETARIO DE LA WEB</b>
						denegar o retirar el acceso y su uso en cualquier momento.
					</p>
					<h3>IDENTIFICACIÓN</h3>
					<p>
						<b>EL PROPIETARIO DE LA WEB</b> en cumplimiento de la Ley
						34/2002, de 11 de julio, de Servicios de la Sociedad de la
						Información y de Comercio Electrónico, le informa de que:
						<br />
						Su titular es: <b>BANSAH & HOHOE S.L.</b>
						<br />
						Su CIF es: <b>B67143974</b>
						<br />
						Su Domicilio profesional está situado en{" "}
						<b>Carrer de Pere IV, 51, 2º 3ª, 08018 Barcelona</b>
					</p>
					<h3>COMUNICACIONES</h3>
					<p>
						Para comunicarse con nosotros, ponemos a su disposición
						diferentes medios de contacto que detallamos a continuación:
						<br />
						Email:{" "}
						<a href="mailto:hello@freshsnow.pro" class="lineLink white">
							<u>hello@freshsnow.pro</u>
						</a>
						<br />
						Dirección postal: en{" "}
						<b>PERE IV, 51, 3º-2ª. 08018 BARCELONA</b>
						<br />
						Todas las notificaciones y comunicaciones entre los usuarios y{" "}
						<b>EL PROPIETARIO DE LA WEB</b>
						se considerarán eficaces, a todos los efectos, cuando se
						realicen a través de cualquier medio de los detallados.
						<br />
					</p>
					<h3>CONDICIONES DE ACCESO Y UTILIZACIÓN</h3>
					<p>
						El sitio web y sus servicios son de acceso libre y gratuito.
						No obstante, <b>EL PROPIETARIO DE LA WEB</b> puede condicionar
						la utilización de algunos de los servicios ofrecidos en su web
						a la previa cumplimentación del correspondiente formulario.
						<br /> El usuario garantiza la autenticidad y actualidad de
						todos aquellos datos que comunique a{" "}
						<b>EL PROPIETARIO DE LA WEB</b> y será el único responsable de
						las manifestaciones falsas o inexactas que realice.
						<br /> El usuario se compromete expresamente a hacer un uso
						adecuado de los contenidos y servicios de{" "}
						<b>EL PROPIETARIO DE LA WEB</b> y a no emplearlos para, entre
						otros:
						<br />
						Difundir contenidos delictivos, violentos, pornográficos,
						racistas, xenófobos, ofensivos, de apología del terrorismo o,
						en general, contrarios a la ley o al orden público.
					</p>
					<ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
						<li>
							Introducir en la red virus informáticos o realizar
							actuaciones susceptibles de alterar, estropear, interrumpir
							o generar errores o daños en los documentos electrónicos,
							datos o sistemas físicos y lógicos de{" "}
							<b>EL PROPIETARIO DE LA WEB</b> o de terceras personas; así
							como obstaculizar el acceso de otros usuarios al sitio web
							y a sus servicios mediante el consumo masivo de los
							recursos informáticos a través de los cuales{" "}
							<b>EL PROPIETARIO DE LA WEB</b> presta sus servicios.
						</li>
						<li>
							Intentar acceder a las cuentas de correo electrónico de
							otros usuarios o a áreas restringidas de los sistemas
							informáticos de <b>EL PROPIETARIO DE LA WEB</b> o de
							terceros y, en su caso, extraer información.
						</li>
						<li>
							Vulnerar los derechos de propiedad intelectual o
							industrial, así como violar la confidencialidad de la
							información de <b>EL PROPIETARIO DE LA WEB</b> o de
							terceros.
						</li>
						<li>Suplantar la identidad de cualquier otro usuario.</li>
						<li>
							Reproducir, copiar, distribuir, poner a disposición de, o
							cualquier otra forma de comunicación pública, transformar o
							modificar los contenidos, a menos que se cuente con la
							autorización del titular de los correspondientes derechos o
							ello resulte legalmente permitido.
						</li>
					</ul>
					<p>
						Todos los contenidos del sitio web, como textos, datos de
						clima, fotografías, gráficos, imágenes, iconos, tecnología,
						software, así como su diseño gráfico y códigos fuente,
						constituyen una obra cuya propiedad pertenece a{" "}
						<b>EL PROPIETARIO DE LA WEB</b> sin que puedan entenderse
						cedidos al usuario ninguno de los derechos de explotación
						sobre los mismos más allá de lo estrictamente necesario para
						el correcto uso de la web.
						<br /> En definitiva, los usuarios que accedan a este sitio
						web pueden visualizar los contenidos y efectuar, en su caso,
						copias privadas autorizadas siempre que los elementos
						reproducidos no sean cedidos posteriormente a terceros, ni se
						instalen a servidores conectados a redes, ni sean objeto de
						ningún tipo de explotación.
						<br /> Asimismo, todas las marcas, nombres comerciales o
						signos distintivos de cualquier clase que aparecen en el sitio
						web son propiedad de <b>EL PROPIETARIO DE LA WEB</b> sin que
						pueda entenderse que el uso o acceso al mismo atribuya al
						usuario derecho alguno sobre los mismos.
						<br />
						<br />
						La distribución, modificación, cesión o comunicación pública
						de los contenidos y cualquier otro acto que no haya sido
						expresamente autorizado por el titular de los derechos de
						explotación quedan prohibidas.
						<br /> El establecimiento de un hiperenlace no implica en
						ningún caso la existencia de relaciones entre{" "}
						<b>EL PROPIETARIO DE LA WEB</b> y el propietario del sitio web
						en la que se establezca, ni la aceptación y aprobación por
						parte de <b>EL PROPIETARIO DE LA WEB</b> de sus contenidos o
						servicios.
						<br />
						<b>EL PROPIETARIO DE LA WEB</b> no se responsabiliza del uso
						que cada usuario le dé a los materiales puestos a disposición
						en este sitio web ni de las actuaciones que realice en base a
						los mismos.
						<br />
					</p>
					<h3>
						EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD EN EL ACCESO Y LA
						UTILIZACIÓN
					</h3>
					<p>
						El contenido del presente sitio web es de carácter general y
						tiene una finalidad meramente informativa, sin que se
						garantice plenamente el acceso a todos los contenidos, ni su
						exhaustividad, corrección, vigencia o actualidad, ni su
						idoneidad o utilidad para un objetivo específico.
						<br /> EL PROPIETARIO DE LA WEB excluye, hasta donde permite
						el ordenamiento jurídico, cualquier responsabilidad por los
						daños y perjuicios de toda naturaleza derivados de:
					</p>
					<ul>
						<li>
							La imposibilidad de acceso al sitio web o la falta de
							veracidad, exactitud, exhaustividad y/o actualidad de los
							contenidos, así como la existencia de vicios y defectos de
							toda clase de los contenidos transmitidos, difundidos,
							almacenados, puestos a disposición, a los que se haya
							accedido a través del sitio web o de los servicios que se
							ofrecen.
						</li>
						<li>
							La presencia de virus o de otros elementos en los
							contenidos que puedan producir alteraciones en los sistemas
							informáticos, documentos electrónicos o datos de los
							usuarios.
						</li>
					</ul>
					<p>
						El incumplimiento de las leyes, la buena fe, el orden público,
						los usos del tráfico y el presente aviso legal como
						consecuencia del uso incorrecto del sitio web. En particular,
						y a modo ejemplificativo, <b>EL PROPIETARIO DE LA WEB</b> no
						se hace responsable de las actuaciones de terceros que
						vulneren derechos de propiedad intelectual e industrial,
						secretos empresariales, derechos al honor, a la intimidad
						personal y familiar y a la propia imagen, así como la
						normativa en materia de competencia desleal y publicidad
						ilícita.
						<br /> Asimismo,
						<b>EL PROPIETARIO DE LA WEB</b> declina cualquier
						responsabilidad respecto a la información que se halle fuera
						de esta web y no sea gestionada directamente por nuestro
						webmaster. La función de los links que aparecen en esta web es
						exclusivamente la de informar al usuario sobre la existencia
						de otras fuentes susceptibles de ampliar los contenidos que
						ofrece este sitio web. <b>EL PROPIETARIO DE LA WEB</b> no
						garantiza ni se responsabiliza del funcionamiento o
						accesibilidad de los sitios enlazados; ni sugiere, invita o
						recomienda la visita a los mismos, por lo que tampoco será
						responsable del resultado obtenido.{" "}
						<b>EL PROPIETARIO DE LA WEB</b> no se responsabiliza del
						establecimiento de hipervínculos por parte de terceros.
					</p>
					<h3>
						PROCEDIMIENTO EN CASO DE REALIZACIÓN DE ACTIVIDADES DE
						CARÁCTER ILÍCITO
					</h3>
					<p>
						En el caso de que cualquier usuario o un tercero considere que
						existen hechos o circunstancias que revelen el carácter
						ilícito de la utilización de cualquier contenido y/o de la
						realización de cualquier Aviso legal, Política privacidad y
						política cookies actividad en las páginas web incluidas o
						accesibles a través del sitio web, deberá enviar una
						notificación a <b>EL PROPIETARIO DE LA WEB</b> identificándose
						debidamente y especificando las supuestas infracciones.
					</p>
					<h3>PUBLICACIONES</h3>
					<p>
						La información administrativa facilitada a través del sitio
						web no sustituye la publicidad legal de las leyes, normativas,
						planes, disposiciones generales y actos que tengan que ser
						publicados formalmente a los diarios oficiales de las
						administraciones públicas, que constituyen el único
						instrumento que da fe de su autenticidad y contenido. La
						información disponible en este sitio web debe entenderse como
						una guía sin propósito de validez legal.
					</p>
					<h3>LEGISLACIÓN APLICABLE</h3>
					<p>
						Las condiciones presentes se regirán por la legislación
						Europea y en este caso dependerá de la Autoridad de control,
						AEPD, legislación española vigente.
						<br /> La lengua utilizada será el castellano.
					</p>
				</div>
			</article>
		</section>
	</Layout>
);

export default AvisoPage;
